.err_container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #152a68;
  padding: 20px;
}

h1.err_title {
  font-size: 100px;
  font-weight: bold;
  color: #a73232;
}

p.err_text {
  font-size: 20px;
  color: #a73232;
  text-align: center;
}

.err_return {
  margin-top: 50px;
  border: 1px solid;
  padding: 15px 25px;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  font-size: 20px;
  transition: all ease 0.3s;
  cursor: pointer;
}

.err_return:hover {
  color: #000;
  background: #fff;
}
