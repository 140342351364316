.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #00000077;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 50;
}
