/* CSS for the switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Keep the switch track color as it is */
  transition: 0.4s; /* Add a smooth transition effect */
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: rgb(
    19,
    19,
    87
  ); /* Change the color of the toggle ball to blue */
  transition: 0.4s; /* Add a smooth transition effect */
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #ccc; /* Keep the switch track color when checked */
}

input:checked + .slider:before {
  transform: translateX(26px);
}

@media only screen and (max-width: 599px) {
  .switch {
    width: 45px; /* Smaller switch width */
    height: 22px; /* Smaller switch height */
  }

  .slider {
    border-radius: 20px; /* Adjust border-radius for smaller switch */
  }

  .slider:before {
    height: 15px; /* Smaller toggle ball height */
    width: 16px; /* Smaller toggle ball width */
    /* Optional: You might want to adjust the position as well */
  }

  input:checked + .slider:before {
    /* Adjust this value if you change the size of the switch or the toggle */
    transform: translateX(20px); /* Smaller translateX value */
  }
}
