.modal {
  width: clamp(50%, 700px, 90%);

  margin: auto;
  padding: 0 2rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: flex-start;
  background-color: white;
  padding: 1rem;
}

body.modal-open {
  overflow: hidden;
}

.modal2 {
  width: clamp(50%, 700px, 70%);

  margin: auto;
  /* padding: 0 2rem; */
  /* border-radius: 12px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: flex-start;
  /* background-color: white;
  padding: 1rem; */
}

body.modal2-open {
  overflow: hidden;
}
