.parallax > use {
  animation: move-forever 30s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
.parallax > use:nth-child(5) {
  /* Ship */
  animation-delay: -1s;
  animation-duration: 18s;
}
.parallax > use:nth-child(6) {
  /* Plane */
  animation-delay: 0s;
  animation-duration: 10s;
}
.parallax > use:nth-child(7) {
  animation-delay: -4s;
  animation-duration: 20s;
}
.parallax > use:nth-child(8) {
  animation-delay: -3s;
  animation-duration: 22s;
}
.parallax > use:nth-child(9) {
  animation-delay: -2s;
  animation-duration: 24s;
}
.parallax > use:nth-child(10) {
  animation-delay: -1s;
  animation-duration: 26s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
  .content {
    height: 20vh;
  }
  h1 {
    font-size: 24px;
  }
}

.waves {
  position: relative;
  width: 100%;
  height: 10vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 200px;
  max-height: 300px;
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
  .content {
    height: 20vh;
  }
  h1 {
    font-size: 24px;
  }
}

/*Shrinking for mobile*/
@media (max-width: 500px) {
  .waves {
    width: 100%;
    height: 10vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
  }
  .content {
    height: 20vh;
  }
  h1 {
    font-size: 24px;
  }
}
