@tailwind base;
@tailwind components;
@import "flowbite";
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.floatingBtn {
  -webkit-tap-highlight-color: transparent;
}

.noTapHighlight {
  -webkit-tap-highlight-color: transparent;
}

.quiz-button {
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
}

@keyframes fillBar {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.loading-bar {
  background-color: rgb(250 204 21); /* or any color you prefer */
  height: 5px; /* adjust as needed */
  width: 0%;
  animation-fill-mode: forwards; /* keeps the state at the end of the animation */
}

.striped-progress-bar {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
}

@keyframes moveStripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 0;
  }
}

.striped-progress-animated {
  animation: moveStripes 2s linear infinite;
}
